<template>
  <div
    id="dashboard"
    class="d-flex flex-row flex-wrap flex-grow-1 flex-shrink-1"
    style="width: 100%;"
  >
    <div
      class="d-flex flex-row flex-wrap"
      style="width: 100%;"
      v-if="!internetState"
    >
      <div
        class="d-flex flex-row flex-wrap justify-center mt-4"
        style="width: 100%;"
        v-if="pageLoading"
      >
        <v-progress-circular indeterminate color="primary" />
      </div>
      <div
        v-else
        class="d-flex justify-space-between"
        style="width: 100%;height: calc(100vh - 60px);"
      >
        <div
          style="min-width: 408px;overflow-y:auto;padding: 20px;flex: 8 1 0%;"
        >
          <div class="d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap">
            <v-card
              class="box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"
            >
              <div class="d-flex flex-column">
                <v-avatar color="rgba(9, 55, 255, 0.21)" size="60">
                  <i
                    class="material-icons-outlined"
                    style="color: #0937ff !important"
                    >shopping_basket</i
                  >
                </v-avatar>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex flex-row flex-full flex-wrap">
                  <h4 class="font font-weight-medium mx-3 mt-1 grey--text">
                    Total Suppliers
                  </h4>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        small
                        color="#09c711"
                        class="vc--verified-icon"
                        >verified_user</v-icon
                      >
                    </template>
                    <span class="ft font-weight-medium"
                      >Verified Suppliers</span
                    >
                  </v-tooltip>
                </div>
                <div class="d-flex flex-row flex-full flex-wrap">
                  <span class="font font-size-lg font-weight-bold ml-3">{{
                    totals.totalSuppliers
                  }}</span>
                </div>
              </div>
            </v-card>
            <v-card
              class="box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"
            >
              <div class="d-flex flex-column">
                <v-avatar color="rgba(227, 77, 254, 0.22)" size="60">
                  <i
                    class="material-icons-outlined"
                    style="color: #af06e4 !important;"
                    >sanitizer</i
                  >
                </v-avatar>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex flex-row flex-full flex-wrap">
                  <h4 class="ft font-weight-medium mx-3 mt-1 grey--text">
                    Total Lubes
                  </h4>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        small
                        color="#09c711"
                        class="vc--verified-icon"
                        >verified_user</v-icon
                      >
                    </template>
                    <span class="ft font-weight-medium">All Lubes</span>
                  </v-tooltip>
                </div>
                <div class="d-flex flex-row flex-full flex-wrap">
                  <span
                    class="font font-weight-bold font-size-lg text-lg ml-3"
                    >{{ totals.totalLubes }}</span
                  >
                </div>
              </div>
            </v-card>
            <v-card
              class="box-shadow-light d-flex flex-row flex-wrap flex-grow-1 pa-3 ma-1"
            >
              <div class="d-flex flex-column">
                <v-avatar color="rgba(255, 152, 0, 0.19)" size="60">
                  <i
                    class="material-icons-outlined"
                    style="color: rgb(255, 152, 0);"
                    >people</i
                  >
                </v-avatar>
              </div>
              <div class="d-flex flex-column">
                <div class="d-flex flex-row flex-full flex-wrap">
                  <h4 class="font font-weight-medium mx-3 mt-1 grey--text">
                    Total Customers
                  </h4>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-on="on"
                        small
                        color="#09c711"
                        class="vc--verified-icon"
                        >verified_user</v-icon
                      >
                    </template>
                    <span class="ft font-weight-medium"
                      >Verified Customers</span
                    >
                  </v-tooltip>
                </div>
                <div class="d-flex flex-row flex-full flex-wrap">
                  <span class="font font-weight-bold font-size-lg  ml-3">{{
                    totals.totalCustomers
                  }}</span>
                </div>
              </div>
            </v-card>
          </div>
          <div
            class="d-flex flex-row my-2 mt-6"
            style="justify-content: space-between"
          >
            <h4 class="font font-weight-medium font-size-lg">
              Daily Sales Reports
            </h4>
            <div class="font font-weight-medium font-size-md">
              Start Date:
              <input
                type="date"
                class="box-shadow-light pa-1 ml-2 mr-2"
                v-model="startDate"
                placeholder="yyyy-mm-dd"
              />
              End Date:
              <input
                v-model="endDate"
                placeholder="yyyy-mm-dd"
                class="box-shadow-light pa-1 ml-2"
                type="date"
              />
            </div>
          </div>

          <div class="d-flex flex-row flex-grow-1 flex-shrink-1 flex-wrap">
            <v-card
              :loading="subLoading"
              class="box-shadow-light flex-grow-1 d-flex flex-row py-2 rounded-sm"
            >
              <apexcharts
                class="d-flex"
                height="500"
                style="width: 100% !important;"
                type="bar"
                :options="chartOptions"
                :series="fuelSalesSeries"
              ></apexcharts>
            </v-card>
          </div>
        </div>
        <div
          class="details-sidebar--drawer-bg"
          style="width:400px;box-sizing: border-box; height: 100%;overflow-y: auto;word-break: break-word"
        >
          <div
            v-if="!isNotificationTurnedOn"
            class="d-flex flex-row flex-grow-1  justify-center  mt-10"
          >
            <div
              class="d-flex flex-column flex-grow-1 notification-container align-center"
            >
              <img src="@/assets/notification.svg" style="width: 120px;" />
              <p class="font font-weight-bold font-size-lg mx-0">
                Turn On Notifications
              </p>
              <p class="font font-weight-medium font-size-sm text-center px-5">
                Receive notifications on activities that takes place on the
                system
              </p>
              <p>
                <v-btn
                  rounded
                  depressed
                  @click="turnOnNotification"
                  color="primary"
                  class="ft font-weight-medium"
                >
                  Turn On
                </v-btn>
              </p>
            </div>
          </div>
          <template v-else>
            <div
              v-if="notifications.length > 0"
              flat
              class="mt-2 "
              style="border-radius: 5px;"
            >
              <div class="box-shadow-light title dash-side-container">
                <h3 class="font font-weight-medium text-md ml-1">
                  Notifications
                </h3>
              </div>
              <div
                v-ripple
                v-for="notification in notifications"
                :key="notification.id"
                :class="notification.unread ? 'box-shadow-light' : ''"
                class="d-flex flex-row mx-1 mt-1"
                :style="{
                  background: notification.unread ? 'white' : '#f3f3f3',
                  cursor: 'pointer'
                }"
                @click="markAsRead(notification)"
              >
                <div class="d-flex flex-column pa-3 ma-1">
                  <v-avatar size="36" color="primary"
                    ><span class="ft font-weight-bold white--text">{{
                      notification.event.charAt(0)
                    }}</span></v-avatar
                  >
                </div>
                <div class="d-flex flex-column flex-grow-1 mt-3">
                  <p
                    class="font font-size-sm px-1"
                    :class="
                      notification.unread
                        ? 'font-weight-bold'
                        : 'font-weight-medium grey--text text--darken-3'
                    "
                  >
                    {{ notification.payload }}
                    <v-chip
                      v-if="notification.userId"
                      label
                      small
                      color="primary"
                      class="ml-3"
                      >By {{ notification.userId.name }}</v-chip
                    >
                  </p>
                </div>
                <div
                  v-if="notification.unread"
                  class="d-flex flex-column pb-2 pr-2"
                  style="justify-content: flex-end;"
                >
                  <span
                    style="width: 10px; height: 10px; border-radius: 10px;background: red;"
                  ></span>
                </div>
              </div>
            </div>
            <div v-else flat class=" white mt-3 " style="border-radius: 5px;">
              <div class="box-shadow-light title dash-side-container">
                <h3 class="font font-weight-medium text-md ml-1">
                  Notifications
                </h3>
              </div>

              <!-- Empty notifications -->
              <EmptyPage
                :image="require(`@/assets/notification.svg`)"
                :title="'No notification right now'"
                :imageWidth="'120px'"
                :subTitle="placeholderText"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
    <SnackBar
      :showSnackBar="showSnackBar"
      :message="message"
      :status="status"
    />
  </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/messaging";
import UAParser from "ua-parser-js";
//import CardName from "../components/CardName";
import SnackBarMixins from "../mixins/SnackBarMixins";
import { env, roundAccurately } from "../utils/resolvers";
import { mapGetters } from "vuex";
//const messaging = firebase.messaging();
import moment from "moment";
export default {
  name: "Dashboard",
  components: {
    EmptyPage: () => import(`../components/EmptyPage`),
    SnackBar: () => import(`../components/SnackBar`)
  },
  computed: {
    ...mapGetters({
      notifications: "settings/getNotifications",
      pageLoading: "getIsPageLoading",
      totals: "dashboard/getTotals",
      sales: "dashboard/getTotalSales",
      subLoading: "getIsSubLoading"
    })
  },
  data: function() {
    return {
      placeholderText:
        "Receive notifications on activities that takes place on the system",

      items: ["All Updates", "Popular"],
      tab: null,
      internetState: false,
      show: false,

      isNotificationTurnedOn: false,
      isNotificationDenied: false,
      chartOptions: {
        chart: {
          type: "bar",
          height: 550
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "15%",
            endingShape: "rounded"
          }
        },
        colors: ["#e20639", "#e838e8", "#952898"],
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          title: {
            text: "Total Sales"
          }
        },
        fill: {
          opacity: 1
        }
      },
      startDate: null,
      endDate: null,
      fuelSalesSeries: [
        {
          name: "Fuel Sales",
          data: []
        },
        {
          name: "Lube Sales",
          data: []
        },
        {
          name: "Shop Sales",
          data: []
        }
      ]
    };
  },
  mixins: [SnackBarMixins],
  methods: {
    turnOnNotification() {
      if (firebase.messaging.isSupported()) {
        Notification?.requestPermission()
          ?.then(response => {
            switch (response) {
              case "granted":
                this.isNotificationTurnedOn = true;
                this.isNotificationDenied = false;
                this.getNotificationToken();
                this.$store.dispatch("settings/notifications", {
                  take: 20,
                  offset: 0
                });
                return;
              case "denied":
                this.isNotificationDenied = true;
                this.isNotificationTurnedOn = false;
                this.$store.commit("UPDATE_SNACKBAR", true, { root: true });
                this.$store.commit(
                  "UPDATE_MESSAGE",
                  "Permission denied, system won't be able to send notifications",
                  { root: true }
                );
                this.$store.commit("UPDATE_STATUS", "red", { root: true });
                return;
              default:
                break;
            }
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        Notification.requestPermission(data => {
          switch (data) {
            case "granted":
              this.isNotificationTurnedOn = true;
              this.isNotificationDenied = false;
              this.getNotificationToken();
              this.$store.dispatch("settings/notifications", {
                take: 20,
                offset: 0
              });
              return;
            case "denied":
              this.isNotificationDenied = true;
              this.isNotificationTurnedOn = false;
              this.$store.commit("UPDATE_SNACKBAR", true, { root: true });
              this.$store.commit(
                "UPDATE_MESSAGE",
                "Permission denied, system won't be able to send notifications",
                { root: true }
              );
              return;
          }
        });
      }
    },
    getNotificationToken() {
      if (firebase.messaging.isSupported()) {
        const messaging = firebase.messaging();
        messaging
          .getToken({
            vapidKey: env("VUE_APP_FCM_VAPID_KEY")
          })
          .then(currentToken => {
            if (currentToken) {
              this.sendTokenToServer(currentToken);
            }
          })
          .catch(() => {
            this.$store.dispatch(
              "showSnackBar",
              {
                snackBar: true,
                message: `Unable to register this browser for push notifications`,
                status: "red"
              },
              { root: true }
            );
          });
      } else {
        this.$store.dispatch(
          "showSnackBar",
          {
            snackBar: true,
            message: `Browser doesn't support push notifications`,
            status: "red"
          },
          { root: true }
        );
      }
    },
    sendTokenToServer(currentToken) {
      const parser = new UAParser(window.navigator.userAgent.toLowerCase());
      let payload = {
        browser:
          window.navigator.brave &&
          window.navigator.brave.isBrave.name === "isBrave"
            ? "Brave"
            : parser.getBrowser().name,
        token: currentToken
      };

      this.$store.dispatch("settings/updateNotificationToken", {
        ...payload
      });
    },
    markAsRead(notification) {
      this.$store.dispatch("settings/markNotificationAsRead", notification.id);
    }
  },
  watch: {
    sales(value) {
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: [
            ...new Set([
              ...value.fuelSales.map(sale =>
                moment(sale.salesDate).format("YYYY-MM-DD")
              ),
              ...value.lubeSales.map(sale =>
                moment(sale.date).format("YYYY-MM-DD")
              ),
              ...value.shopSales.map(sale =>
                moment(sale.salesDate).format("YYYY-MM-DD")
              )
            ])
          ]
        }
      };
      this.fuelSalesSeries[0].data = value.fuelSales.map(sale =>
        roundAccurately(sale.totalAmountSold, 2)
      );
      this.fuelSalesSeries[1].data = value.lubeSales.map(sale =>
        roundAccurately(sale.total, 2)
      );
      this.fuelSalesSeries[2].data = value.shopSales.map(sale =>
        roundAccurately(sale.salesAmount, 2)
      );
    },
    startDate(value) {
      if (value && this.endDate) {
        this.$store.dispatch("dashboard/sales", {
          startDate: value,
          endDate: this.endDate
        });
      }
    },
    endDate(value) {
      if (value && this.startDate) {
        this.$store.dispatch("dashboard/sales", {
          startDate: this.startDate,
          endDate: value
        });
      }
    }
  },
  created() {
    // todo change from making request to get update from backend from updating using the payload
    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage(() => {
        this.$store.dispatch("settings/notifications", { take: 20, offset: 0 });
      });
    }
    if (!("navigator" in window)) {
      this.$store.dispatch(
        "showSnackBar",
        {
          snackBar: true,
          message: `Browser doesn't support push notifications`,
          status: "red"
        },
        { root: true }
      );
    }
    this.$store.dispatch("settings/notifications", { take: 20, offset: 0 });
    this.startDate = moment()
      .subtract(1, "month")
      .format("YYYY-MM-DD");
    this.endDate = moment().format("YYYY-MM-DD");
    this.$store.dispatch("dashboard/sales", {
      startDate: moment()
        .subtract(1, "month")
        .format("YYYY-MM-DD"),
      endDate: moment().format("YYYY-MM-DD")
    });
    this.$store.dispatch("dashboard/totals");
    switch (Notification.permission) {
      case "default":
        this.isNotificationTurnedOn = false;
        this.isNotificationDenied = false;
        return;
      case "denied":
        this.isNotificationTurnedOn = false;
        this.isNotificationDenied = true;
        return;
      case "granted":
        this.isNotificationTurnedOn = true;
        this.isNotificationDenied = false;
        return;
      default:
        return;
    }
  }
};
</script>

<style scoped>
.row {
  display: flex;
  flex-wrap: wrap;
  maring-right: -10px;
}

.stretch-card {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
}

.grid-margin {
  margin-bottom: 20px;
}

.card {
  border: 2px;
  border-radius: 2px;
  display: flex;
  height: 400px;
  flex-direction: column;
  background-color: #fff;
  background-clip: border-box;
}
.card2 {
  border: 2px;
  border-radius: 2px;
  display: flex;
  height: 180px;
  width: 560px;
  margin-top: -10px;
  flex-direction: column;
  background-color: #fff;
  background-clip: order-box;
}
.block {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.extreme {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
}
.stretch-card > .card {
  width: 100%;
  min-width: 100%;
}
.bottom {
  border-bottom: 1px solid #aacfcf;
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  color: #2f4053;
}
.button {
  background-color: #04a9f3;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.button1 {
  background-color: #1eb2a6;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
.button2 {
  background-color: #ffa3ad;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}

.button3 {
  background-color: #b590ca;
  border: none;
  font-size: 10px;
  color: white;
  padding: 4px 15px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin-right: 10px;
  cursor: pointer;
}
#app {
  margin: 0 auto;
  padding: 20px;
  max-width: 600px;
}

.bitcoin-price {
  .vtc {
    height: 250px;
    font-size: 12px;
    @media (min-width: 699px) {
      height: 350px;
    }
  }
  .grid,
  .labels {
    line {
      stroke: rgba(#f69119, 0.5);
    }
  }
  .x-labels {
    .label {
      text {
        display: none;
      }
      line {
        opacity: 0.3;
      }
      &:nth-child(6n + 1),
      &:first-child {
        text {
          display: block;
        }
        line {
          opacity: 1;
        }
      }
    }
  }
  .curve-btc {
    .stroke {
      stroke: #f69119;
      stroke-width: 2;
    }
    .fill {
      fill: url(#btcFill);
      fill-opacity: 0.5;
    }
  }
}
</style>
